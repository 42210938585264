/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1an6a0x --style4 bg--top --full --parallax" name={"uvod"} border={""} parallax={true} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="--center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--128 w--300 ls--002 lh--1" content={"<span style='color: white'>Restaurace<br>Comida</span>"}>
              </Title>

              <Button className="btn-box btn-box--shape3" content={"Denní menu"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pl--20 pr--20 pt--60" name={"informace"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":514}}>
              
              <Title className="title-box" content={"Restaurace v centru starého Olomouce"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":734}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/80/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/80/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/80/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/80/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/80/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/80/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1gqy15h bg--center-h --parallax pb--80 pl--20 pr--20 pt--80" name={"menu"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --shape3 --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left pl--0 pr--0" style={{"maxWidth":1525}} content={"<span style='color: white;'>Denní menu<span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--80 pt--60" name={"menu-2"} parallax={true}>
          
          <ColumnWrap className="column__flex --shape3 --left el--2 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--shape3">
              
              <Title className="title-box fs--48" content={"Pondělí 1.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Polévka:<br>Kuřecí vývar s fazolovými boby, těstovinou a opečeným toustem."}>
              </Text>

              <Text className="text-box" content={"1. Husarská roláda, bramborová kaše, zeleninový salát — 100 Kč<br>2. Těstoviny s kuřecím masem, špenátem a smetanou — 100 Kč<br>3. Česnekové vepřové řízečky, americké brambory, tatarka — 100 Kč<br>4. Zeleninový salát s kuřecími stripsy, dresink, pečivo — 100 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3">
              
              <Title className="title-box fs--48" content={"Uterý, 2.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Polévka:<br>Kuřecí vývar s fazolovými boby, těstovinou a opečeným toustem."}>
              </Text>

              <Text className="text-box" content={"1. Husarská roláda, bramborová kaše, zeleninový salát — 100 Kč<br>2. Těstoviny s kuřecím masem, špenátem a smetanou — 100 Kč<br>3. Česnekové vepřové řízečky, americké brambory, tatarka — 100 Kč<br>4. Zeleninový salát s kuřecími stripsy, dresink, pečivo — 100 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20">
              
              <Title className="title-box fs--48" content={"Středa, 3.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Polévka:<br>Kuřecí vývar s fazolovými boby, těstovinou a opečeným toustem."}>
              </Text>

              <Text className="text-box" content={"1. Husarská roláda, bramborová kaše, zeleninový salát — 100 Kč<br>2. Těstoviny s kuřecím masem, špenátem a smetanou — 100 Kč<br>3. Česnekové vepřové řízečky, americké brambory, tatarka — 100 Kč<br>4. Zeleninový salát s kuřecími stripsy, dresink, pečivo — 100 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20">
              
              <Title className="title-box fs--48" content={"Čtvrtek, 4.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Polévka:<br>Kuřecí vývar s fazolovými boby, těstovinou a opečeným toustem."}>
              </Text>

              <Text className="text-box" content={"1. Husarská roláda, bramborová kaše, zeleninový salát — 100 Kč<br>2. Těstoviny s kuřecím masem, špenátem a smetanou — 100 Kč<br>3. Česnekové vepřové řízečky, americké brambory, tatarka — 100 Kč<br>4. Zeleninový salát s kuřecími stripsy, dresink, pečivo — 100 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20" style={{"maxWidth":914}}>
              
              <Title className="title-box fs--48" content={"Pátek, 5.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Polévka:<br>Kuřecí vývar s fazolovými boby, těstovinou a opečeným toustem."}>
              </Text>

              <Text className="text-box" content={"1. Husarská roláda, bramborová kaše, zeleninový salát — 100 Kč<br>2. Těstoviny s kuřecím masem, špenátem a smetanou — 100 Kč<br>3. Česnekové vepřové řízečky, americké brambory, tatarka — 100 Kč<br>4. Zeleninový salát s kuřecími stripsy, dresink, pečivo — 100 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20" style={{"maxWidth":914}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1qrryh0 bg--center-h --parallax pb--80 pl--20 pr--20 pt--80" name={"informace"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --shape3 el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center lh--14" content={"<span style='color: white;'>Holandská 854/1, Brno<br>info@vase-stranky.com<br>+420 797 811 2X0<span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex el--2 pl--0 pr--0 flex--top" columns={"2"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Title className="title-box fs--48" content={"Comida"}>
              </Title>

              <Text className="text-box mt--20" style={{"maxWidth":437}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--right" content={"NE — ČT"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--right mt--02" style={{"color":"var(--color-variable-1)"}} content={"9:00 — 23:00"}>
              </Subtitle>

              <Text className="text-box text-box--right" content={"PÁ — SO"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--right mt--02" style={{"color":"var(--color-variable-1)"}} content={"11:00 — 02:00"}>
              </Subtitle>

              <Text className="text-box text-box--right" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}